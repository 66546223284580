export const handleAddIncomeSubmit = async (event, setShowAddIncomeModal) => {
  event.preventDefault();
  const { description, amount, date_received, source } = event.target.elements;
  const token = localStorage.getItem("token");
  try {
    const response = await fetch("https://money-couple.com/api/income/", {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description: description.value,
        amount: amount.value,
        date_received: date_received.value,
        source: source.value,
      }),
    });

    if (response.ok) {
      console.log("Income added successfully");
      setShowAddIncomeModal(false);
    } else {
      console.error("Failed to add income");
    }
  } catch (error) {
    console.error("Error adding income:", error);
  }
};

export const handleEditIncomeSubmit = async (
  event,
  incomeId,
  closeEditModal,
  refreshIncomesList,
) => {
  event.preventDefault();
  const { description, amount, date_received, source } = event.target.elements;
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `https://money-couple.com/api/income/edit/${incomeId}/`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          description: description.value,
          amount: amount.value,
          date_received: date_received.value,
          source: source.value,
        }),
      },
    );

    if (response.ok) {
      console.log("Income edited successfully");
      closeEditModal();
      refreshIncomesList();
    } else {
      console.error("Failed to edit income");
    }
  } catch (error) {
    console.error("Error editing income:", error);
  }
};

export const handleListIncomesClick = async (
  setShowListIncomesModal,
  setIncomesList,
) => {
  setShowListIncomesModal(true);
  const token = localStorage.getItem("token");
  try {
    const response = await fetch("https://money-couple.com/api/income/list/ ", {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setIncomesList(data);
    } else {
      console.error("Failed to fetch incomes");
    }
  } catch (error) {
    console.error("Error fetching incomes:", error);
  }
};

export const handleDeleteIncome = async (incomeId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `https://money-couple.com/api/income/delete/${incomeId}/`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    );

    if (response.ok) {
      console.log("Income deleted successfully");
    } else {
      console.error("Failed to delete income");
    }
  } catch (error) {
    console.error("Error deleting income:", error);
  }
};

export const fetchIncomeDetails = async (incomeId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `https://money-couple.com/api/income/edit/${incomeId}/`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch income details");
      return null;
    }
  } catch (error) {
    console.error("Error fetching income details:", error);
    return null;
  }
};

export const fetchTotalIncomes = async (selectedDate, setTotalIncomes) => {
  const token = localStorage.getItem("token");
  const adjustedMonth = selectedDate.month + 1;

  try {
    const response = await fetch(
      `https://money-couple.com/api/total-income/${selectedDate.year}/${adjustedMonth}/`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    );

    if (response.ok) {
      const data = await response.json();
      setTotalIncomes(data.total_income);
    } else {
      console.error("Failed to fetch total incomes");
    }
  } catch (error) {
    console.error("Error fetching total incomes:", error);
  }
};
