import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  LinearProgress,
} from "@mui/material";
import { fetchTotalIncomes } from "../utils/incomes_utils";
import { fetchTotalExpenses } from "../utils/expenses_utils"; // Ensure this path is correct

const IncomeExpensesCard = () => {
  const [month, setMonth] = useState(0); // Start with January as index 0
  const [year, setYear] = useState(new Date().getFullYear());
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  // Fetch total incomes and expenses whenever the selected month or year changes
  useEffect(() => {
    const selectedDate = { year, month };
    fetchTotalIncomes(selectedDate, setTotalIncomes);
    fetchTotalExpenses(selectedDate, setTotalExpenses);
  }, [month, year]);

  // Example budgets and expenses (can be dynamically fetched as well)
  const budgets = {
    groceries: 500,
    utilities: 150,
    entertainment: 200,
  };
  const expenses = {
    groceries: 450,
    utilities: 100,
    entertainment: 250,
  };

  // Calculate utilization for each category
  const utilization = {
    groceries: (expenses.groceries / budgets.groceries) * 100,
    utilities: (expenses.utilities / budgets.utilities) * 100,
    entertainment: (expenses.entertainment / budgets.entertainment) * 100,
  };

  return (
    <Card
      sx={{
        flex: 1,
        margin: 1,
        textAlign: "center",
        p: 2,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
          Incomes vs. Expenses
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="month-select-label">Month</InputLabel>
          <Select
            labelId="month-select-label"
            id="month-select"
            value={month}
            label="Month"
            onChange={handleMonthChange}
            sx={{ marginBottom: 2 }}
          >
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((m, index) => (
              <MenuItem key={index} value={index}>
                {m}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider sx={{ my: 2 }} />
        <Typography>
          ${totalIncomes} vs. ${totalExpenses}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
          Savings Rate:{" "}
          {totalIncomes > 0
            ? `${(((totalIncomes - totalExpenses) / totalIncomes) * 100).toFixed(2)}%`
            : "0.00%"}
        </Typography>

        <Typography variant="body2" sx={{ mb: 1 }}>
          Month-on-Month: TBD {/* Replace with dynamic comparison */}
        </Typography>
        <Box sx={{ mt: 2 }}>
          {Object.keys(budgets).map((key) => (
            <Box key={key} sx={{ mb: 1 }}>
              <Typography variant="body2">
                {key.charAt(0).toUpperCase() + key.slice(1)} Budget:{" "}
                {utilization[key].toFixed(1)}%
              </Typography>
              <LinearProgress
                variant="determinate"
                value={Math.min(utilization[key], 100)}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  "& .MuiLinearProgress-bar": {
                    backgroundColor:
                      utilization[key] > 100 ? "error.main" : "primary.main",
                  },
                }}
              />
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default IncomeExpensesCard;
