import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
  Paper,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  handleAddIncomeSubmit,
  handleEditIncomeSubmit,
  handleListIncomesClick,
  handleDeleteIncome,
  fetchIncomeDetails,
} from "../utils/incomes_utils";

const IncomeModal = ({ open, handleClose, incomeDetails }) => {
  const initialFormData = {
    description: "",
    amount: "",
    date_received: "",
    source: "",
  };

  const [incomeData, setIncomeData] = useState(initialFormData); // For the initial form
  const [editIncomeData, setEditIncomeData] = useState(initialFormData); // For the edit form
  const [showListModal, setShowListModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false); // State for the Edit modal
  const [incomesList, setIncomesList] = useState([]);
  const [selectedIncome, setSelectedIncome] = useState(null); // For holding selected income for editing

  const handleChange = (e, setState) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const resetForm = () => {
    setIncomeData(initialFormData); // Reset form to initial values
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleAddIncomeSubmit(e, handleClose);
    resetForm();
  };

  const handleListIncomes = async () => {
    await handleListIncomesClick(setShowListModal, setIncomesList);
  };

  const handleDelete = async (incomeId) => {
    await handleDeleteIncome(incomeId);
    await handleListIncomes();
  };

  const handleEdit = async (incomeId) => {
    const incomeDetails = await fetchIncomeDetails(incomeId);
    if (incomeDetails) {
      const formattedDate = incomeDetails.date_received
        ? incomeDetails.date_received.split("T")[0] // Format the date as YYYY-MM-DD
        : ""; // Handle undefined or missing date

      setSelectedIncome(incomeDetails); // Set the details of the selected income
      setEditIncomeData({
        ...incomeDetails,
        date_received: formattedDate, // Assign the formatted date to the state
      });
      setShowEditModal(true); // Open the edit modal
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    await handleEditIncomeSubmit(
      e,
      selectedIncome.id,
      () => setShowEditModal(false), // Close the edit modal
      handleListIncomes, // Refresh the incomes list after editing
    );
  };

  return (
    <>
      {/* Initial Submit Income Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 20,
            width: 400,
          }}
        >
          {/* Close button at the top-right corner */}
          <IconButton
            onClick={() => {
              handleClose();
              resetForm(); // Reset form when the modal is closed via close button
            }}
            color="error"
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 10,
              boxShadow: 1,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Button onClick={handleListIncomes} startIcon={<ListIcon />}>
            List
          </Button>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={incomeData.description}
              onChange={(e) => handleChange(e, setIncomeData)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Amount"
              name="amount"
              type="number"
              value={incomeData.amount}
              onChange={(e) => handleChange(e, setIncomeData)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Date Received"
              name="date_received"
              type="date"
              value={incomeData.date_received}
              onChange={(e) => handleChange(e, setIncomeData)}
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Source</InputLabel>
              <Select
                name="source"
                value={incomeData.source}
                onChange={(e) => handleChange(e, setIncomeData)}
              >
                <MenuItem value="BrickAbode">BrickAbode</MenuItem>
                <MenuItem value="Viasat">Viasat</MenuItem>
                <MenuItem value="Bolsa Doc">FAPESP</MenuItem>
                <MenuItem value="Bolsa Doc II">Value 1</MenuItem>
                <MenuItem value="SempreIT">Value 1</MenuItem>
                <MenuItem value="Signal">Value 1</MenuItem>
              </Select>
            </FormControl>
            <Button type="submit" color="primary" variant="contained" fullWidth>
              Save
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Modal to display the list of incomes */}
      <Modal open={showListModal} onClose={() => setShowListModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            p: 3,
            width: 700,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={() => setShowListModal(false)}
            color="error"
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 10,
              boxShadow: 1,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Table */}
          <TableContainer
            component={Paper}
            sx={{ borderRadius: 2, boxShadow: 2 }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", color: "primary.main" }}>
                    Id
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "primary.main" }}>
                    Description
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "primary.main" }}>
                    Amount
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "primary.main" }}>
                    Date Received
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "primary.main" }}>
                    Source
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "primary.main" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incomesList.map((income, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{income.id}</TableCell>
                    <TableCell>{income.description}</TableCell>
                    <TableCell>${income.amount}</TableCell>
                    <TableCell>{income.date_received}</TableCell>
                    <TableCell>{income.source}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleEdit(income.id)}
                        color="primary"
                        startIcon={<EditIcon />}
                        sx={{ mr: 1 }}
                        variant="outlined"
                        size="small"
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => handleDelete(income.id)}
                        color="error"
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        size="small"
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>

      {/* Modal for editing income */}
      <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 20,
            width: 400,
          }}
        >
          {/* Close button at the top-right corner */}
          <IconButton
            onClick={() => setShowEditModal(false)}
            color="error"
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 10,
              boxShadow: 1,
            }}
          >
            <CloseIcon />
          </IconButton>

          <form onSubmit={handleEditSubmit}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={editIncomeData.description}
              onChange={(e) => handleChange(e, setEditIncomeData)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Amount"
              name="amount"
              type="number"
              value={editIncomeData.amount}
              onChange={(e) => handleChange(e, setEditIncomeData)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Date Received"
              name="date_received"
              type="date"
              value={editIncomeData.date_received}
              onChange={(e) => handleChange(e, setEditIncomeData)}
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Source</InputLabel>
              <Select
                name="source"
                value={editIncomeData.source}
                onChange={(e) => handleChange(e, setEditIncomeData)}
              >
                <MenuItem value="BrickAbode">BrickAbode</MenuItem>
                <MenuItem value="Viasat">Viasat</MenuItem>
                <MenuItem value="Bolsa Doc">FAPESP</MenuItem>
                <MenuItem value="Bolsa Doc II">Value 1</MenuItem>
              </Select>
            </FormControl>
            <Button type="submit" color="primary" variant="contained" fullWidth>
              Save Changes
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default IncomeModal;
